import React from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {
  count?: number;
  as?: React.ElementType;
  height?: number;
};
const ListLoader = ({ count = 5, as = 'li', height = 80 }: Props) => {
  const WrapperElement = as;
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <WrapperElement key={index}>
          <Skeleton height={height} style={{ lineHeight: 1 }} />
        </WrapperElement>
      ))}
    </>
  );
};

export default ListLoader;
