import * as Parts from '@components/Parts';
import Joi from 'joi';
import * as StandaloneParts from '@components/StandaloneParts';
import { StandalonePartTypes, Tilstandsrapport } from '@supertakst/model-common';

type ValidationErrorType = {
  generellInformasjon: Joi.ValidationResult;
  areal: Joi.ValidationResult;
  tekniskVerdi: Joi.ValidationResult;
  parts: Joi.ValidationResult[];
};

export const validateBefaring = (befaring: Tilstandsrapport) => {
  // TODO: This should be set on befaring.

  let standaloneValidations = {};
  for (const [key, value] of Object.entries(StandalonePartTypes)) {
    const schema = StandaloneParts[`v${befaring.modelVersion}`][key].schema;
    const data = befaring[value.id];
    if (data) {
      standaloneValidations[value.id] = schema.validate(befaring[value.id], {
        presence: 'required',
        abortEarly: false,
      });
    }
  }

  const partsValidations = befaring.parts
    .map((part) => {
      const partSpec = Parts[`v${befaring.modelVersion}`][`${part?.partType}`];
      if (!partSpec) {
        console.warn(
          `Unable to find part spec for Parts[v${befaring.modelVersion}][${part?.partType}]`
        );
        return;
      }
      const schema = Parts[`v${befaring.modelVersion}`][`${part?.partType}`]
        .schema as Joi.ObjectSchema;
      return schema.validate(part, {
        presence: 'required',
        abortEarly: false,
      });
    })
    .filter((p) => p !== undefined);

  return {
    ...standaloneValidations,
    parts: partsValidations,
  } as ValidationErrorType;
};

export const getBefaringProgress = (befaring: Tilstandsrapport) => {
  const errors = validateBefaring(befaring);

  const { parts, ...rest } = errors;

  const partsWithErrors = parts.reduce((acc, curr) => {
    if (curr.error) acc += 1;
    return acc;
  }, 0);

  const standalonePartsWithErrors = Object.values(rest).reduce((acc, curr): number => {
    if (curr.error) acc += 1;
    return acc;
  }, 0);

  const numErrors = partsWithErrors + standalonePartsWithErrors;
  const numParts = befaring.parts.length + Object.keys(rest).length;
  return Math.ceil(100 - (numErrors / numParts) * 100);
};
